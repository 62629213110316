<template>
  <div class="row">
    <div class="col-12 col-lg-5 c g">
      <div class="card card-body text-center">
        <h1 class="text-success">
          {{ data.total }}
        </h1>
        الكل
        <div class="row">
          <div class="col-12 col-lg-6 g text-centerbnxcf12345678">
            <h5>
              {{ data.today }}
            </h5>
            اليوم
          </div>
          <div class="col-12 col-lg-6 g text-center">
            <h5>
              {{ data.month }}
            </h5>
            هذا الشهر
          </div>
        </div>
      </div>

      <div class="card card-body text-center">
        <h1 class="text-success">
          {{ schools }}
        </h1>
        حسابات مدرستي (الكل)
      </div>
      <div class="card card-body text-center">
        <h1 class="text-success">
          {{ m }}
        </h1>
        حسابات مدرستي (المشرفين فقط)
      </div>
      <div class="card card-body text-center">
        <h1 class="text-success">
          {{ m_o }}
        </h1>
        طلب مدفوع للمشرفين
      </div>
      <div class="card card-body text-center">
        <h1 class="text-success">
          {{ students_excel }}
        </h1>
        عمليات تصدير ملف الارشاد
      </div>
      <div class="card card-body text-center">
        <h1 class="text-success">
          {{ active }}
        </h1>
        الحسابات الفعالة
      </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body">
        <div class="col-12 table-responsive">
          عمليات التثبيت الجديدة <button class="btn btn-sm text-danger" @click="deleteStats()">
            <i class="fa fa-trash"></i>
            حذف الاحصائيات
          </button>
          <table class="table table-hover table-bordered table-sm">
            <thead>
              <th>
                التاريخ
              </th>
              <th>
                عدد التحميلات الجديدة
              </th>
            </thead>
            <tbody>
              <template v-for="(day, i) in arr">
                <tr :key="i" v-if="day.key.includes('installed')">
                  <td>
                    {{ day.key.split('_')[1] }}
                  </td>
                  <td>
                    {{ day.value }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card card-body">
        <div class="col-12 table-responsive">
          عمليات الانذرارت الجديدة <button class="btn btn-sm text-danger" @click="deleteStats2()">
            <i class="fa fa-trash"></i>
            حذف الاحصائيات
          </button>
          <table class="table table-hover table-bordered table-sm">
            <thead>
              <th>
                التاريخ
              </th>
              <th>
                عدد الاستخدامات الجديدة
              </th>
            </thead>
            <tbody>
              <template v-for="(day, i) in arr">
                <tr :key="i" v-if="day.key.includes('alerts')">
                  <td>
                    {{ day.key.split('_')[1] }}
                  </td>
                  <td>
                    {{ day.value }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-12 table-responsive">
          عمليات الشهادات الجديدة <button class="btn btn-sm text-danger" @click="deleteStats2()">
            <i class="fa fa-trash"></i>
            حذف الاحصائيات
          </button>
          <table class="table table-hover table-bordered table-sm">
            <thead>
              <th>
                التاريخ
              </th>
              <th>
                عدد الاستخدامات الجديدة
              </th>
            </thead>
            <tbody>
              <template v-for="(day, i) in arr">
                <tr :key="i" v-if="day.key.includes('certs')">
                  <td>
                    {{ day.key.split('_')[1] }}
                  </td>
                  <td>
                    {{ day.value }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-12 table-responsive">
          الخطة الاسبوعية
          <br>
          <ul>
            <li>
              المستخدمين (بدون تكرار): {{ weekly.active }}
            </li>
            <li>
              العمليات المخزنة (الجدول التاني): {{ weekly.old }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            weekly: {},
            data: {},
            schools: 0,
            m: 0,
            m_o: 0,
            students_excel: 0,
            arr: []
        }
    },
    created(){
        this.getArchive();
    },
    methods: {
        getArchive(){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/home', {
                jwt: localStorage.getItem("jwt")
            })
            .then(function(response){
                g.loading = false
                g.data = response.response.orders
                g.schools = response.response.schools
                g.m = response.response.m
                g.m_o = response.response.m_o
                g.students_excel = response.response.students_excel
                g.arr = response.response.installed
                g.active = response.response.active
                g.weekly = response.response.weekly
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        },
        deleteStats(){
          var g = this;
          if(confirm("are you sure?")){
             $.post(api + '/admin/reset-stats-installed', {
                jwt: localStorage.getItem("jwt")
            })
            .then(function(response){
                location.reload()
            })
            .fail(function(){
                g.loading = false
                alert("حدث خطأ")
            })
          }
        },
        deleteStats2(){
          var g = this;
          if(confirm("are you sure?")){
             $.post(api + '/admin/reset-stats-alerts', {
                jwt: localStorage.getItem("jwt")
            })
            .then(function(response){
                location.reload()
            })
            .fail(function(){
                g.loading = false
                alert("حدث خطأ")
            })
          }
        }
    }
}
</script>

<style>

</style>